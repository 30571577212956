import React, { Component } from "react";
import "../Shopping.css";
import { connect } from "react-redux";
import { fetchProducts } from "../actions/ProductActions";
import { addToCart } from "../actions/CartActions";
import { Helmet } from "react-helmet";
import Layout from "../Layout/Layout";
import Voluntariadoform from "../pages/Voluntariadoform";

class HomePrincipal2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: null,
    };
  }

  openModal = (product) => {
    this.setState({ product });
  };
  closeModal = () => {
    this.setState({ product: null });
  };
  render() {
    const { product } = this.state;
    /**Galeria img Nuestro juego */
    return (
      <div>
        <Layout>
          <Helmet>
            <title>La Libertad Avanza</title>
            <meta
              name="description"
              content="La Libertad Avanza voluntariado"
            />
            <meta charset="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <link rel="icon" href="%PUBLIC_URL%libertad-avanza/logo-la2.png" />
            <html lang="es" />
            <meta name="theme-color" content="#000000" />
            <meta name="robots" content="index, follow"></meta>
          </Helmet>
          <div className="rect-200">
            <header className="centrar">
              <br />
              <img
                src="../../libertad-avanza/logo-la3.png"
                className="img-fondo-nuevo222"
                alt="debate"
              />
            </header>
            <main className="centrar centrar-h">
              {/* <h1 className="frase">"Fortalecimiento de participación democrática:<br/> PROSPERIDAD PARA TODOS"</h1> */}

              {/*           <img
                     src="../../mca/flyer.jpg"
                     className="img-fondo-nuevo"
                     alt="debate"
                   />  */}
            </main>

            <div>
              <div className="logo-nav-2-redes">
                <img
                  src="../../mca/seguinos.png"
                  className="redes-sociales-22"
                  alt="facebook"
                />
                <a href="https://www.facebook.com/LibertadAvanzaOficial" target="_blank">
                  <img
                    src="../../mca/fb@2x.png"
                    className="redes-sociales-22"
                    alt="facebook"
                  />
                </a>

                <a href="https://www.instagram.com/lalibertadavanzaoficial/">
                  <img
                    src="../../mca/ig@2x.png"
                    className="redes-sociales-22"
                    alt="instagram"
                  />
                </a>

                <a href="https://twitter.com/LibertadAvanza_">
                  <img
                    src="../../mca/tw@2x.png"
                    className="redes-sociales-22"
                    alt=""
                  />
                </a>
              </div>
            </div>

            <main className="container-juego centrar">
              <br />
              <br />
              <br />
              <br />
              <Voluntariadoform />
            </main>
          </div>
        </Layout>
      </div>
    );
  }
}

export default connect((state) => ({ products: state.products.Items }), {
  fetchProducts,
  addToCart,
})(HomePrincipal2);
