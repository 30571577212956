import React from "react";
import { Link } from "react-router-dom";
import "../Shopping.css";
import { connect } from "react-redux";
import Cart from "../components/Cart";
import Boton from "../Layout/Boton";
import { removeFromCart } from "../actions/CartActions";
import ProfileScreen from "../screens/ProfileScreen";
import ProfileScreen2 from "../screens/ProfileScreen2";
import MiEspacio from "../Layout/MiEspacio";
import MisDatos from "./MisDatos";
import MisDatos2 from "./MisDatos2";
import LayoutUsu from "../Layout/LayoutUsu"
import Alertacont from "../pages/Alertacont";
import MenuUser from "../Layout/MenuUser"
import { USER_DETAILS_SUCCESS } from "../constants/userConstants";

class Navigation extends React.Component {
  render() {
    const openMenu = () => {
      document.querySelector(".sidebar").classList.add("open");
    };
    const closeMenu = () => {
      document.querySelector(".sidebar").classList.remove("open");
    };
    const openMenuCart = () => {
      document.querySelector(".sidebar-CART").classList.add("open");
    };
    const closeMenuCart = () => {
      document.querySelector(".sidebar-CART").classList.remove("open");
    };
    const { cartItems } = this.props;
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
 console.log("holaaa",userInfo)
    return (
      <div>
       
        <nav className="nav1">
          <div className="contenedor-nav">
            <div className="logo-nav-1">
              <Boton />
              <Link to="/">
                <img
                  src="../../libertad-avanza/logo-la2.png"
                  className="icon-logo"
                  alt="Sindicato de trabajadores"
                />
              </Link>
            </div>
            {/* <MiEspacio/> */}
            <div className="logo-nav">
  
            </div>
            
            <div className="logo-nav-2">
            <p className="color-black">
                          {userInfo ? (
                                <p className="saludo color-orange"> <MenuUser/></p>
                          ) : (
                            <></>
                          )}
                        </p>
            
              <button className="icono-cart" onClick={openMenuCart}>
                {cartItems.length === 0 ? (
                  <div className="cart cart-header">
                    <img
                      src="../../mca/icon-cart.png"
                      className="carrito-1"
                      alt="carrito"
                    />
                    <span>0</span>
                  </div>
                ) : (
                  <div className="cart cart-header">
                    <img
                      src="../../mca/icon-cart.png"
                      className="carrito-1"
                      alt="carrito de compras"
                    />
                    {cartItems.length}{" "}
                  </div>
                )}
              </button>

              <button className="icono" onClick={openMenu}>
              <img
                      src="../../mca/icon-menu.png"
                      className="carrito-112"
                      alt="carrito de compras"
                    />
              </button>
            </div>
          </div>
        </nav>

        
        
        <nav className="nav2">
          <div className="contenedor-nav">
          
              <Link to="/">
                <img
                  src="../../libertad-avanza/logo-la2.png"
                  className="icon-logo"
                  alt="La Libertad Avanza"
                />
              </Link>
            
    

            
            {/* <MisDatos2 />
            <Alertacont/> */}




            <div className="logo-nav-2-carrito">
            <ProfileScreen />

            <Link className="lista-sidebar-700" to="/shopping">
              {" "}
              <p className="color-white">Shopping</p>
            </Link>
            <Link className="lista-sidebar-700" to="/apoyanos">
              {" "}
              <p className="color-white">Apóyanos</p>
            </Link>
            <Link className="lista-sidebar-700" to="/notas">
              {" "}
              <p className="color-white">Noticias</p>
            </Link>
            <Link className="lista-sidebar-700" to="/conocenos">
              {" "}
              <p className="color-white">Quienes somos</p>
            </Link>

            <Link className="lista-sidebar-700" to="/fiscalizacion">
              {" "}
              <p className="color-white">Fiscaliza</p>
            </Link>
            <Link className="lista-sidebar-700" to="/contacto">
              {" "}
              <p className="color-white">Contacto</p>
            </Link>
            <p className="color-black">
                          {userInfo ? (
                                <p className="saludo color-orange"> <MenuUser/></p>
                          ) : (
                            <></>
                          )}
                        </p>
              <button className="icono-cart-700" onClick={openMenuCart}>
                {cartItems.length === 0 ? (
                  <div className="cart cart-header">
                    <img
                      src="../../mca/icon-cart.png"
                      className="carrito-1"
                      alt="carrito"
                    />
                    <span>0</span>
                  </div>
                ) : (
                  <div className="cart cart-header">
                    <img
                      src="../../mca/icon-cart.png"
                      className="carrito-1"
                      alt="carrito"
                    />
                    {cartItems.length}{" "}
                  </div>
                )}
              </button>
            </div>
          </div>
        </nav>
        {/* <div className="nav3">
        <MiEspacio />
          <div className="centrar-derecha">
          <div className="logo-nav-2-redes">
            <a href="">
              <img
                src="../../mca/instagram.png"
                className="redes22"
                alt="instagram"
              />
            </a>

            <a href="">
              <img
                src="../../mca/icon-twiter.png"
                className="redes22"
                alt="twiter"
              />
            </a>

            <a href="">
              <img
                src="../../mca/facebook.png"
                className="redes22"
                alt="facebook"
              />
            </a>
          </div> 
        </div>
        </div> */}
        <div className="sidebar-CART">
          <button className="close-modal-cart" onClick={closeMenuCart}>
            &times;
          </button>

          <Cart />
          <div className="product-details-actions2">
          <Link to="/carrito">
            <button className="button-red-carrito">Proceder al pago</button>
          </Link>
        </div>
        </div>
        <div className="sidebar">
          <ul className="categories">
            <button className="close-modal" onClick={closeMenu}>
              &times;
            </button>
            





            <div className="lista-sidebar">

              <ProfileScreen2 />
            </div>
            
{/* <MisDatos /> */}

           
            {/* <LayoutUsu/> */}
            
            <hr/>
            <div className="lista-sidebar">
              <Link to="/shopping">
                {/* <img
                  src="/mca/icon-shopping-r.png"
                  className="redes-s-salir"
                  alt="shopping"
                /> */}
                <p className="menu "> Shopping</p>{" "}
              </Link>
            </div>
            <br />
            <hr />
            <div className="lista-sidebar">
              <Link to="/apoyanos">
                {/* <img
                  src="/mca/group-11.png"
                  className="redes-s-salir"
                  alt="Apóyanos"
                /> */}
                <p className="menu "> Apóyanos</p>{" "}
              </Link>
            </div>
            <br />
            <hr />
            <div className="lista-sidebar">
              <Link to="/notas">
                {/* <img
                  src="/mca/icon-notas.png"
                  className="redes-s-salir"
                  alt="Noticias"
                /> */}
                <p className="menu "> Noticias</p>{" "}
              </Link>
            </div>
            <br />
            <hr />
            <div className="lista-sidebar">
              <Link to="/fiscalizacion">
                {/* <img
                  src="/mca/group-150.png"
                  className="redes-s-salir"
                  alt="Fiscaliza"
                /> */}
                <p className="menu "> Fiscaliza</p>{" "}
              </Link>
            </div>
            <br />
            <hr />
            <div className="lista-sidebar">
              <Link to="/conocenos">
                {/* <img
                  src="/mca/icon-qs.png"
                  className="redes-s-salir"
                  alt="contacto"
                /> */}
                <p className="menu "> Quienes somos</p>{" "}
              </Link>
            </div>
            <br />
            <hr />
            <div className="lista-sidebar">
              <Link to="/contacto">
                {/* <img
                  src="/mca/icon-contacto.png"
                  className="redes-s-salir"
                  alt="quienes somos"
                /> */}
                <p className="menu "> Contacto</p>{" "}
              </Link>
            </div>
            <br />
            <hr />
          </ul>
        </div>
      </div>
    );
  }
}
export default connect(
  (state) => ({
    cartItems: state.cart.cartItems,
  }),
  { removeFromCart }
)(Navigation);
