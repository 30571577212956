
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { logout, update } from "../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import "../Shopping.css";

function ProfileScreen2(props) {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div>
      {/* {userInfo ? (
        <div className="lista-sidebar">
        <Link to="/">
          <button type="button" onClick={handleLogout} className="sesion">
          <img
                src="/mca/icon-salir.png"
                className="redes-s-salir2"
                alt="Cerrar sesión"
              />
            <p >Cerrar sesión</p><br/>
          </button>
        </Link>
        </div>
      ) : (
        <div className="lista-sidebar">
        <Link to="/signin" className="sesion">
        <img
                src="/mca/icon-entrar.png"
                className="redes-s"
                alt="Iniciar sesión"
              />
          <p >Iniciar sesión</p>
        </Link>
        
        </div>
      )} */}
      <Link to="/">
          

            <h2 style={{color:"white"}}>Inicio</h2><br/>
          
        </Link>
    </div>
  );
}

export default ProfileScreen2;